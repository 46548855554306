<template>
    <b-modal :id="$route.name + 'CreateModal'" title="Добавить регион" size="md" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="clearData()">
        <b-row>
            <b-col cols="12">
                <b-row>
                    <b-col cols="6">
                        <label>Выберите свою область</label>
                        <b-input v-model="region.name_ru" placeholder="Согдийская область" />
                    </b-col>
                    <b-col cols="6">
                        <label>Ваша область (английсский)</label>
                        <b-input v-model="region.name" placeholder="Sughd Province" />
                    </b-col>
                    <b-col class="mt-2" cols="6">
                        <label>Ваша страна </label>
                        <v-select
                            v-model="country_select"
                            @input="setSelected"
                            placeholder="Страна"
                            label="name"
                            :options="countries"
                            class="select-size-md"
                        />
                    </b-col>
                </b-row>
                <b-row class="justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    @click="createRegion()"
                    class="mt-2 mx-1"
                    variant="primary">Добавить</b-button>
                    <b-button @click="btnCancel()" class="mt-2 mx-1" variant="secondary">Отмена</b-button>
                </b-row>
            </b-col>
        </b-row>
    </b-modal>
</template>
<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        vSelect,
        ToastificationContent,
    },
    props: ['countries'],
    data(){
        return {
            region: {
                name: '',
                name_ru: '',
                country_id: '',
            },
            country_select: undefined,
            submitButtonDisabled: false,
        }
    },
    methods: {
        createRegion(){
            this.submitButtonDisabled = true
            this.$http
                .post('addresses/regions', this.region)
                .then(res => {
                    this.btnCancel()
                    this.$bvModal.hide('regionModal')
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Успех!',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: `Регион успешно добавлен!`,
                        },
                    })
                    this.$bvModal.hide(this.$route.name + 'CreateModal')
                    this.$emit('refresh')
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                })
                .finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        setSelected(){
            this.region.country_id = this.country_select.id
        },
        btnCancel(){
            this.$bvModal.hide(this.$route.name + 'CreateModal')
            this.clearData()
        },
        clearData(){
            this.region = {
                name: '',
                name_ru: '',
                country_id: '',
            },
            this.country_select = undefined
        }
    }
}
</script>