<template>
    <b-modal id="regionModalEdit" title="Изменить данные региона" size="md" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="opened">
        <b-row>
            <b-col cols="12">
                <b-row>
                    <b-col cols="6">
                        <label>Выберите свою область</label>
                        <b-input v-model="region.name_ru" placeholder="Согдийская область" />
                    </b-col>
                    <b-col cols="6">
                        <label>Ваша область</label>
                        <b-input v-model="region.name" placeholder="Согдийская область" />
                    </b-col>
                    <b-col class="mt-2" cols="6">
                        <label>Ваша страна </label>
                        <v-select
                            v-model="region.country_id"
                            placeholder="Страна"
                            label="name_ru"
                            :reduce="options => options.id"
                            :options="countries"
                            @input="setSelected"
                            class="select-size-md"
                        />
                    </b-col>            
                </b-row>
                <b-row class="justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    @click="editRegion()"
                    class="mt-2 mx-1"
                    variant="primary">Изменить</b-button>
                    <b-button @click="btnCancel()" class="mt-2 mx-1" variant="secondary">Отмена</b-button>
                </b-row>
            </b-col>
        </b-row>
        <map-vue @latLng="latLng" />
    </b-modal>
</template>
<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import mapVue from '../../Map/map.vue'

export default {
    props: ['id'],
    components: {
        vSelect,
        ToastificationContent,
        mapVue
    },
    data(){
        return {
            country_select: undefined,
            region: {
                name: undefined,
                country_id: undefined, 
                name_ru: undefined,
            },
            country: undefined,
            countries: undefined,
            submitButtonDisabled: false,
        }
    },
    mounted(){
    },
    methods: {
        opened(){
            this.$http
                .get(`addresses/regions/${this.id.id}/edit`)
                .then(res => {
                    this.region = res.data,
                    this.country = res.data.country
                    this.country_select = res.data.country
            })

            this.$http
                .get(`addresses/countries`)
                .then(res => {
                    this.countries = res.data
            })
        },
        editRegion(){
            this.submitButtonDisabled = true
            this.$http
                .patch(`addresses/regions/${this.id.id}`, this.region)
                .then(res => {
                    this.btnCancel()
                    // this.$bvModal.hide('regionModalEdit')
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: `Регион успешно изменен!`,
                        },
                    })
                    this.$emit('refresh')
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                })
                .finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        setSelected(){
            this.region.country_id = this.country_select.id
        },
        latLng(latLng){
            this.region.name_ru = latLng.address.state
        },
        btnCancel(){
            this.$bvModal.hide('regionModalEdit')
        }
    }

}
</script>