<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
            <b-spinner variant="primary" label="Text Centered" />
        </div>
        <div v-else>
            <table-region :regions="regions" @editForm="editForm" @refresh="refresh" />
            <modal-region :countries="countries" @refresh="refresh" />
            <modal-region-edit :id="id" @refresh="refresh" />
        </div>
        <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
    </div>
</template>
<script>
import tableRegion from "@/views/component/Table/tableRegion.vue"
import modalRegion from "@/views/component/Modal/ModalRegion/modalRegion.vue"
import modalRegionEdit from "@/views/component/Modal/ModalRegion/modalRegionEdit.vue"
export default {
    components: {
        tableRegion,
        modalRegion,
        modalRegionEdit,
    },
    data(){
        return {
            id: '',
            region: {},
            regions: [],
            countries: [],
            country: {},
            showPreloader: false,
            fields: [ 
                { key: 'checkbox', label: '', thStyle: {width: '30px'}},
                { key: 'id', label: 'Id', sortable: true, thStyle: {width: '40px'}},
                { key: 'name_ru', label: 'Регион', sortable: true, thStyle: {width: '150px'}},
                { key: 'name', label: 'Регион(английский)', sortable: true, thStyle: {width: '150px'}},
                { key: 'country.name_ru', label: 'Страна', sortable: true, thStyle: {width: '150px'}},
            ],
        }
    },
    mounted(){
        this.$store.commit('pageData/setdataCount', null)
        this.$http
            .get('addresses/countries').then(res => {
                this.countries = res.data
            })

        this.openFilter()
    },
    methods: {
        editForm(id){
            this.id = id
            setTimeout(this.openModal, 0);
            this.$http
                .get(`addresses/regions/${id}/edit`)
                .then(res => {
                    this.region = res.data,
                    this.country = res.data.country
            })
        },
        openModal(){
            this.$bvModal.show('regionModalEdit')
        },
        openFilter(){
            let allRoutes = this.$store.state.draggableTab.tabs
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            let arrayRoutes = []
            allRoutes.forEach(element => {
                arrayRoutes.push(element.path)
            });
            if (arrayRoutes.includes(this.$route.path) == true) {
                this.showPreloader = true
                this.$http
                    .get(`${this.$route.name}`, {params: param}).then(res => {
                        this.regions = res.data
                        this.$store.commit('pageData/setdataCount', this.regions.length)
                        this.showPreloader = false
                        this.$store.commit('REFRESH_DATA', false)
                    })
            }else if(arrayRoutes.includes(this.$route.path) == false){
                this.$bvModal.show(this.$route.name + 'filter')
            }
        },
        refresh(){
            this.showPreloader = true
            this.$http
                .get('addresses/regions').then(res => {
                    this.regions = res.data
                    this.$store.commit('pageData/setdataCount', this.regions.length)
                    this.showPreloader = false
                    this.$store.commit('REFRESH_DATA', false)
                })
        },
        sendToParent(tableData){
            this.regions = tableData
            this.$store.commit('pageData/setdataCount', this.regions.length)
        },
    },
    computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val){
            if (val){
                this.$http
                .get(`${this.$route.name}`)
                .then(res =>{
                    this.regions = res.data
                    this.$store.commit('pageData/setdataCount', this.regions.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
                }
            }
    }
}
</script>